@import 'colors';
@import 'mixin';

h1 {
  font-family: $emphasis-font-family;
  font-size: 36px;
  margin-bottom: 20px;
  height: 40px;
}

label {
  color: $my-blue;
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  input,
  textarea,
  select {
    margin-top: 10px;
  }
  &:not(:last-of-type) {
    padding-bottom: 10px;
  }
}

@import 'colors';
@import 'mixin';

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: $bkg;
  -ms-overflow-style: none;
  scrollbar-width: none;
  // background-image: -webkit-repeating-radial-gradient(
  //   center center,
  //   rgba(0, 0, 0, 0.1),
  //   rgba(0, 0, 0, 0.1) 1px,
  //   transparent 1px,
  //   transparent 100%
  // );
  // background-size: 3px 3px;
}

body,
div,
input,
select,
textarea,
a,
button,
pre {
  font-size: 16px;
  font-family: $common-font-family;
  font-weight: 400;
  vertical-align: top;
  color: $text-color;
  border: 0;
  outline: none;
}
textarea {
  resize: none;
}

a,
button {
  cursor: pointer;
  @extend .trstn;
  &:hover {
    @extend .trstn;
  }
}

::selection {
  background-color: $my-blue;
  color: $white;
}

::-moz-selection {
  background-color: $my-blue;
  color: $white;
}

input,
select,
textarea {
  padding: 20px;
  background: $white;
  @include border-radius(5px);
  border: 1px solid $grey-300;
  @extend .bsbb;
  width: 100%;
  height: 60px;
  @extend .trstn;
  &:focus {
    border-color: $my-blue;
    @extend .trstn;
  }
}
textarea {
  @media screen and (min-width: 320px) {
    min-height: 150px;
  }
  @media screen and (min-width: 768px) {
    min-height: 200px;
  }
}

::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

button[type='submit'] {
  cursor: pointer;
  background: $my-blue;
  color: $white;
  height: 60px;
  padding: 0 30px;
  font-weight: 600;
  @include border-radius(5px);
  text-transform: uppercase;
  &[disabled] {
    @include opacity(0.5);
    cursor: not-allowed;
  }
}
